import './App.css';
import playStoreBadge from './images/google-play-badge.png';
import playStoreBadgeDe from './images/google-play-badge-de.png';
import playStoreBadgeIt from './images/google-play-badge-it.png';
import iosStoreBadge from './images/apple-store-badge.png';
import iosStoreBadgeDe from './images/apple-store-badge-de.png';
import iosStoreBadgeIt from './images/apple-store-badge-it.png';
import naneaLogo from './images/nanea-logo.png';

function App() {
  const pathname = window.location.pathname ?? '';
  const lang = navigator.language;
  const langCode = lang.split('-')[0].toLowerCase();

  const path = pathname.split('/')[1];
  const os = path.split(/_(.+)/)[0];
  const redirect = 'https://web.nanea.app/' + path.split(/_(.+)/)[1];

  // skip the page and goes on webapp directly
  window.location.href = redirect;

  const getIosBadge = () => {
    if (langCode === 'de') {
      return iosStoreBadgeDe;
    }
    if (langCode === 'it') {
      return iosStoreBadgeIt;
    }
    return iosStoreBadge;
  };
  const getAndroidBadge = () => {
    if (langCode === 'de') {
      return playStoreBadgeDe;
    }
    if (langCode === 'it') {
      return playStoreBadgeIt;
    }
    return playStoreBadge;
  };

  const getHintText = () => {
    if (langCode === 'de') {
      return `Damit Sie alle Funktionen im vollem Umfang genießen können und ihre Online Bestellung zum Erlebnis wird, laden Sie die Nanea App herunter. `;
    }
    if (langCode === 'it') {
      return 'Fai il download dell\'app per avere sempre a disposizione tutte le funzioni';
    }
    return 'Download the app to fully enjoy all the features!';
  };

  const getContinueText = () => {
    if (langCode === 'de') {
      return 'Für die weitere Bestellung über den Web Browser (klicke hier)';
    }
    if (langCode === 'it') {
      return 'oppure continua nel browser';
    }
    return 'continue in the browser';
  };

  return (
    <div>
      <div className='nanea-logo-container'>
        <img src={naneaLogo} className='nanea-logo' alt='nanea Logo' />
      </div>
      <main className='App text-style'>
        {os !== '' && <p>{getHintText()}</p>}

        {os === 'android' && (
          <a
            href='https://play.google.com/store/apps/details?id=eu.ueberall.nanea'
            rel='noopener'
          >
            <img
              src={getAndroidBadge()}
              className='store-logo'
              alt='Google Play Store Logo'
            />
          </a>
        )}

        {os === 'ios' && (
          <a
            href='https://apps.apple.com/app/nanea/id1562128134'
            rel='noopener'
          >
            <img
              src={getIosBadge()}
              className='store-logo-ios'
              alt='Apple AppStore Logo'
            />
          </a>
        )}
        {os !== '' && (
          <a href={redirect} rel='noopener'>
            <p>{getContinueText()}</p>
          </a>
        )}
        {os === '' && (
          <a
            href='https://play.google.com/store/apps/details?id=eu.ueberall.nanea'
            rel='noopener'
          >
            <img
              src={getAndroidBadge()}
              className='store-logo'
              alt='Google Play Store Logo'
            />
          </a>
        )}

        {os === '' && (
          <a
            href='https://apps.apple.com/app/nanea/id1562128134'
            rel='noopener'
          >
            <img
              src={getIosBadge()}
              className='store-logo-ios'
              alt='Apple AppStore Logo'
            />
          </a>
        )}
      </main>
    </div>
  );
}

export default App;
